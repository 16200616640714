<template>
    <div class="container-fluid">
        <div class="card my-3">
            <div class="card-header d-inline-flex justify-content-between">
                <AdminSideBarToggle/>
                <el-button
                    @click="showSettingsCreateDialog"
                    size="small"
                    type="primary">{{ $t('Add') }}</el-button>
            </div>
            <!-- Optimized settings table -->
            <div class="card-body">
                <el-table
                    v-loading="settingsLoadingState"
                    :data="settings"
                    style="width: 100%">
                    <el-table-column
                        prop="symbol.symbol"
                        fixed="left"
                        min-width="150px"
                        :label="$t('Symbol')"/>
                    <el-table-column
                        prop="trend_on"
                        min-width="90px"
                        :label="$t('Trend on')"/>
                    <el-table-column
                        prop="trend_off"
                        min-width="90px"
                        :label="$t('Trend off')"/>
                    <el-table-column
                        prop="w_period_h1"
                        min-width="110px"
                        :label="$t('W period H1')"/>
                    <el-table-column
                        prop="w_signal_h1"
                        min-width="110px"
                        :label="$t('W signal H1')"/>
                    <el-table-column
                        prop="w_signal_h4"
                        min-width="110px"
                        :label="$t('W signal H4')"/>
                    <el-table-column
                        prop="w_signal_d1"
                        min-width="110px"
                        :label="$t('W signal D1')"/>

                    <el-table-column
                        prop="tenkan_sen_d1"
                        min-width="140px"
                        :label="$t('Tenkan Sen D1')"/>
                    <el-table-column
                        prop="kijun_sen_d1"
                        min-width="110px"
                        :label="$t('Kihun Sen D1')"/>
                    <el-table-column
                        prop="senkou_span_b_d1"
                        min-width="150px"
                        :label="$t('Senkou Span B D1')"/>

                    <el-table-column
                        prop="k4_tenkan"
                        min-width="190px"
                        :label="$t('H4 TenkanH4/TenkanD1')"/>
                    <el-table-column
                        prop="k4_kijun"
                        min-width="140px"
                        :label="$t('H4 Kijun/Tenkan')"/>
                    <el-table-column
                        prop="k4_senkou"
                        min-width="140px"
                        :label="$t('H4 Senkou/Kijun')"/>

                    <el-table-column
                        prop="k1_tenkan"
                        min-width="190px"
                        :label="$t('H1 TenkanH1/TenkanH4')"/>
                    <el-table-column
                        prop="k1_kijun"
                        min-width="140px"
                        :label="$t('H1 Kijun/Tenkan')"/>
                    <el-table-column
                        prop="k1_senkou"
                        min-width="140px"
                        :label="$t('H1 Senkou/Kijun')"/>
                    <el-table-column
                        align="center"
                        min-width="240px"
                        :label="$t('Actions')">
                        <template slot-scope="scope">
                            <el-button
                                size="small"
                                type="primary"
                                @click="showSettingsEditDialog(scope.row)">{{ $t('Edit') }}</el-button>
                            <el-popconfirm
                                @confirm="deleteSettings(scope.row)"
                                :title="$t('Are you sure?')"
                                :confirm-button-text="$t('Yes')"
                                :cancel-button-text="$t('No')"
                                confirm-button-type="danger"
                            >
                                <el-button
                                    slot="reference"
                                    size="small"
                                    type="danger">{{ $t('Delete') }}</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- Optimized settings edit dialog -->
        <el-dialog
            class="dialog"
            :title="settingsForm.invite_id ? $t('Editing settings') : $t('Adding settings')"
            :visible.sync="isSettingsEditDialogVisible"
        >
            <el-form
                @submit.native.prevent
                label-position="left"
                ref="settingsForm"
                :rules="settingsFormRules"
                :model="settingsForm"
                label-width="180px">
                <el-form-item :label="$t('Symbol')" prop="k_symbol_id">
                    <el-select v-model="settingsForm.k_symbol_id" :placeholder="$t('Symbol')">
                        <el-option
                            v-for="symbol in symbols"
                            :key="symbol.k_symbol_id"
                            :label="symbol.symbol"
                            :value="symbol.k_symbol_id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Trend on" prop="trend_on">
                    <el-input-number v-model="settingsForm.trend_on"
                                     controls-position="right"
                                     :step="0.01"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="Trend off" prop="trend_off">
                    <el-input-number v-model="settingsForm.trend_off"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="W period H1" prop="w_period_h1">
                    <el-input-number v-model="settingsForm.w_period_h1"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="W signal H1" prop="w_signal_h1">
                    <el-input-number v-model="settingsForm.w_signal_h1"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="W signal H4" prop="w_signal_h4">
                    <el-input-number v-model="settingsForm.w_signal_h4"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="W signal D1" prop="w_signal_d1">
                    <el-input-number v-model="settingsForm.w_signal_d1"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="Tenkan Sen D1" prop="tenkan_sen_d1">
                    <el-input-number v-model="settingsForm.tenkan_sen_d1"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="Kihun Sen D1" prop="kijun_sen_d1">
                    <el-input-number v-model="settingsForm.kijun_sen_d1"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="Senkou Span B D1" prop="senkou_span_b_d1">
                    <el-input-number v-model="settingsForm.senkou_span_b_d1"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="H4 TenkanH4/TenkanD1" prop="k4_tenkan">
                    <el-input-number v-model="settingsForm.k4_tenkan"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="H4 Kijun/Tenkan" prop="k4_kijun">
                    <el-input-number v-model="settingsForm.k4_kijun"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="H4 Senkou/Kijun" prop="k4_senkou">
                    <el-input-number v-model="settingsForm.k4_senkou"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="H1 TenkanH1/TenkanH4" prop="k1_tenkan">
                    <el-input-number v-model="settingsForm.k1_tenkan"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="H1 Kijun/Tenkan" prop="k1_kijun">
                    <el-input-number v-model="settingsForm.k1_kijun"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>

                <el-form-item label="H1 Senkou/Kijun" prop="k1_senkou">
                    <el-input-number v-model="settingsForm.k1_senkou"
                                     controls-position="right"
                                     :step="0.1"
                                     :precision="2"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="isSettingsEditDialogVisible = false">{{ $t('Close') }}</el-button>
                <el-button size="small" type="primary" @click="submitSettingsForm" :loading="settingsLoadingState">{{ $t('Save') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import AdminSideBarToggle from '@/components/AdminSideBarToggle'

export default {
    name: 'OptimizedSettings',
    components: {
        AdminSideBarToggle,
    },
    i18n: {
        messages: {
            ru: {
                'Add': 'Добавить',
                'Edit': 'Изменить',
                'Delete': 'Удалить',
                'Actions': 'Действия',
                'Are you sure?': 'Вы уверены?',
                'Yes': 'Да',
                'No': 'Нет',
                'Close': 'Закрыть',
                'Save': 'Сохранить',
                'Symbol': 'Пара',
                'Error': 'Ошибка',
                'Error deleting settings': 'Ошибка удаления настроек',
                'Required field': 'Поле обязательно для заполнения',
                'Settings loading error': 'Ошибка загрузки настроек',
                'Symbols loading error': 'Ошибка загрузки валютных пар',
                'Error creating settings': 'Ошибка создания настроек',
                'Error updating settings': 'Ошибка обновления настроек',
            }
        }
    },
    data: function () {
        return {
            isSettingsEditDialogVisible: false,
            settingsForm: {
                id: null,
                k_symbol_id: null,
                trend_on: 0,
                trend_off: 0,
                w_period_h1: 0,
                w_signal_h1: 0,
                w_signal_h4: 0,
                w_signal_d1: 0,
                tenkan_sen_d1: 0,
                kijun_sen_d1: 0,
                senkou_span_b_d1: 0,
                k4_tenkan: 0,
                k4_kijun: 0,
                k4_senkou: 0,
                k1_tenkan: 0,
                k1_kijun: 0,
                k1_senkou: 0,
            },
            settingsFormRules: {
                k_symbol_id: [
                    {
                        required: true,
                        message: () => this.$t('Required field')
                    }
                ]
            },
        }
    },
    computed: {
        symbols: function () {
            return this.$store.getters['admin/fxSymbols/symbols']
        },
        settings: function () {
            return this.$store.getters['admin/optimizedSettings/settings']
        },
        settingsLoadingState: function () {
            return this.$store.getters['admin/optimizedSettings/loadingState']
        }
    },
    methods: {
        showSettingsCreateDialog: function () {
            // prepare form data
            this.settingsForm = {
                id: null,
                k_symbol_id: null,
                trend_on: 0,
                trend_off: 0,
                w_period_h1: 0,
                w_signal_h1: 0,
                w_signal_h4: 0,
                w_signal_d1: 0,
                tenkan_sen_d1: 0,
                kijun_sen_d1: 0,
                senkou_span_b_d1: 0,
                k4_tenkan: 0,
                k4_kijun: 0,
                k4_senkou: 0,
                k1_tenkan: 0,
                k1_kijun: 0,
                k1_senkou: 0,
            }
            // show dialog
            this.isSettingsEditDialogVisible = true
        },
        showSettingsEditDialog: function (settings) {
            // prepare form data
            this.settingsForm = Object.assign({}, settings)
            // show dialog
            this.isSettingsEditDialogVisible = true
        },
        submitSettingsForm: function () {
            this.$refs['settingsForm'].validate(valid => {
                if(!valid) {
                    return
                }

                if(this.settingsForm.id) {
                    // Update
                    this.$store.dispatch('admin/optimizedSettings/updateRequest', this.settingsForm)
                        .catch(() => {
                            this.$notify({
                                title: this.$t('Error'),
                                message: this.$t('Error updating settings'),
                                type: 'error',
                                duration: 4000,
                                showClose: false,
                                dangerouslyUseHTMLString: true,
                            })
                        })
                        .finally(() => this.isSettingsEditDialogVisible = false)
                } else {
                    // Create
                    this.$store.dispatch('admin/optimizedSettings/createRequest', this.settingsForm)
                        .catch(() => {
                            this.$notify({
                                title: this.$t('Error'),
                                message: this.$t('Error creating settings'),
                                type: 'error',
                                duration: 4000,
                                showClose: false,
                                dangerouslyUseHTMLString: true,
                            })
                        })
                        .finally(() => this.isSettingsEditDialogVisible = false)
                }
            })
        },
        deleteSettings: function (settingToDelete) {
            this.$store.dispatch('admin/optimizedSettings/deleteRequest', settingToDelete.id)
                .catch(() => {
                    this.$notify({
                        title: this.$t('Error'),
                        message: this.$t('Error deleting settings'),
                        type: 'error',
                        duration: 4000,
                        showClose: false,
                        dangerouslyUseHTMLString: true,
                    })
                })
        },
    },
    mounted() {
        // Reload symbols
        this.$store.dispatch('admin/fxSymbols/symbolsGetRequest')
            .catch(() => {
                this.$notify({
                    title: this.$t('Error'),
                    message: this.$t('Symbols loading error'),
                    type: 'error',
                    duration: 4000,
                    showClose: false,
                    dangerouslyUseHTMLString: true,
                })
            })

        // Reload settings
        this.$store.dispatch('admin/optimizedSettings/getRequest')
            .catch(() => {
                this.$notify({
                    title: this.$t('Error'),
                    message: this.$t('Settings loading error'),
                    type: 'error',
                    duration: 4000,
                    showClose: false,
                    dangerouslyUseHTMLString: true,
                })
            })
    }
}
</script>

<style scoped>
.el-button+span {
    margin-left: 10px;
}
</style>
